import { Container, Content, Item } from './styles';

import enterpriseImg from '../../assets/enterprise.png'

export function SliderEnterprise() {

  return (
    <Container>
      <img
        src={enterpriseImg}
        alt="Imagem 1" />
    </Container>
  );
}