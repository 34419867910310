import styled from 'styled-components';
import backgroundServices from '../../assets/background-services.jpg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40rem;
  background-image: url(${backgroundServices});
  background-size: cover;
  color: var(--secondary-text);
  
  h1 {
    font-size: 3rem;
  }
  h2 {
    margin-top: 4rem;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
`;

export const Card = styled.div`
  margin-top: 4rem;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction:  column;
  justify-content: space-around;
  width: 24rem;
  height: 15rem;
  border-radius: 0.25rem;
  align-items: center;
  background: var(--shape);
  box-shadow: var(--sombra-padrao);
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
  }

  img {
    color: var(--blue);
  }
  .title {
    font-size: 1.5rem;
    color: var(--purple);
    font-weight: 600;
  }

  .text {
    font-weight: 500;
    color: var(--text);
    text-align: center;
  }
`;