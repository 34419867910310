import { Container, Content } from "./styles";
import Carousel from "react-material-ui-carousel";
import { Paper, Button } from "@mui/material";

import imgSlider1 from "../../assets/slider-1.png";
import shadows from "@mui/material/styles/shadows";

interface ModalItemPropsDTO {
  key: number;
  item: {
    name: string;
    description: string;
    image: string;
  };
  openModalPlans: any;
}

interface PromotionsPropsDTO {
  openModalPlans: Function;
}
interface ModalPropsDTO {
  openModalPlans: Function;
}

function ItemModal({ item, openModalPlans }: ModalItemPropsDTO) {
  return (
    <Paper sx={{ boxShadow: "none" }}>
      <Content>
        <h2>{item.name}</h2>
        <h3>{item.description}</h3>
        <button onClick={openModalPlans}>Ver Planos</button>
      </Content>
      <img src={item.image} alt="Image" />
    </Paper>
  );
}
function Modal({ openModalPlans }: PromotionsPropsDTO) {
  var items = [
    {
      name: "Conexões sem limites",
      description: "Conheça nossos planos de internet",
      image: imgSlider1,
    },
    {
      name: "Melhores planos",
      description: "Planos que cabem no seu bolso",
      image: imgSlider1,
    },
  ];

  return (
    <Carousel
      sx={{ width: "100%", boxShadow: "none" }}
      animation="slide"
      duration={500}
      interval={5000}
      autoPlay={true}
    >
      {items.map((item, i) => (
        <ItemModal openModalPlans={openModalPlans} key={i} item={item} />
      ))}
    </Carousel>
  );
}

export function Promotions({ openModalPlans }: PromotionsPropsDTO) {
  return (
    <Container>
      <Modal openModalPlans={openModalPlans} />
    </Container>
  );
}
