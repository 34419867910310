import { Container, Content, Card } from "./styles";

export function Us() {
  return (
    <Container>
      {/* <h1>Quem Somos</h1>

      <hr />

      <span className="story">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed iaculis nulla in est egestas, auctor ultricies ligula ornare. Maecenas ac dolor congue felis luctus elementum a in sapien. Proin mattis lacinia sem, quis tempor eros. Aliquam finibus, sapien sed aliquam convallis, risus nisi malesuada nisi, et gravida neque orci tincidunt turpis. Nam arcu nulla, consequat a commodo ut, fermentum eu nisi. Nam in augue ultrices, varius nulla sed, posuere enim. Maecenas vitae justo blandit augue molestie malesuada et eu elit. Etiam sollicitudin tempor ante, eget convallis lorem dignissim eu.
      </span> */}

      <Content>
        <Card>
          <span className="title">Missão</span>

          <span className="text">
            Prover um serviço de alta tecnologia com o máximo de qualidade,
            velocidade e eficiência, superando sempre as expectativas e prezando
            pela satisfação dos nossos clientes.
          </span>
        </Card>
        <Card>
          <span className="title">Visão</span>

          <span className="text">
            Ser reconhecida como uma empresa confiável e inovadora, oferecendo
            serviços de acesso à internet diferenciados, de alta qualidade e
            excelência e com um elevado padrão de atendimento a todos.
          </span>
        </Card>
        <Card>
          <span className="title">Valores</span>

          <span className="text">
            Transparência, ética, respeito ao cliente, aperfeiçoamento
            profissional e responsabilidade social. Valorização dos nossos
            colaboradores para resultar em um atendimento de alta qualidade, a
            fim de manter o nosso maior tesouro: Nossos clientes.
          </span>
        </Card>
      </Content>
    </Container>
  );
}
