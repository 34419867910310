import { Container, LogoCelphone, LogoContent } from "./styles";
import { NavLink, useNavigate } from "react-router-dom";
import { Stack as Menu, Button, Link, Typography } from "@mui/material";

import logoHorizontalImg from "../../assets/logo-horizontal.png";

interface PropsDTO {
  openModalPlans: any;
}

export function Header({ openModalPlans }: PropsDTO) {
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <LogoContent>
          <NavLink to="/">
            <img src={logoHorizontalImg} alt="Acessenet" />
          </NavLink>
          <LogoCelphone>
            <Link
              style={{ textDecoration: "none", color: "var(--text)" }}
              target="_blank"
              href="https://wa.me/558631421710"
              rel="noreferrer"
            >
              <span>(86) 3142 1610</span>
            </Link>
            {/* <Link style={{ textDecoration: 'none', color: 'var(--text)' }} target="_blank" href="https://wa.me/5586999673850" rel="noreferrer">
            <span>(86) 9 9967 3850</span>
          </Link> */}
          </LogoCelphone>
        </LogoContent>

        <Menu direction="row" alignItems="center" spacing={2}>
          <Button color="primary" variant="text" onClick={() => navigate("/")}>
            <Typography
              sx={{ textTransform: "capitalize" }}
              variant="h6"
              style={{ fontWeight: "500" }}
            >
              Início
            </Typography>
          </Button>

          <Button color="primary" variant="text" onClick={openModalPlans}>
            <Typography
              sx={{ textTransform: "capitalize" }}
              variant="h6"
              style={{ fontWeight: "500" }}
            >
              Planos
            </Typography>
          </Button>

          {/* <Button
            color="primary"
            variant="text"
            onClick={() => navigate("/contrato")}
          >
            <Typography
              sx={{ textTransform: "capitalize" }}
              variant="h6"
              style={{ fontWeight: "500" }}
            >
              Contrato
            </Typography>
          </Button> */}
          <Button
            color="primary"
            variant="text"
            onClick={() => navigate("/empresa")}
          >
            <Typography
              sx={{ textTransform: "capitalize" }}
              variant="h6"
              style={{ fontWeight: "500" }}
            >
              Empresa
            </Typography>
          </Button>

          {/* <Button
            color="primary"
            variant="text"
            onClick={() => navigate("/contato")}
          >
            <Typography
              sx={{ textTransform: "capitalize" }}
              variant="h6"
              style={{ fontWeight: "500" }}
            >
              Contato
            </Typography> 
          </Button>*/}

          <Button color="primary" variant="contained">
            <Link
              color="inherit"
              underline="none"
              target="_blank"
              href="https://wa.me/558631421710?text=Quero%20ser%20acessenet,%20como%20faço?"
              rel="noreferrer"
            >
              <Typography
                sx={{ textTransform: "capitalize" }}
                variant="h6"
                style={{ fontWeight: "500" }}
              >
                Assine Já
              </Typography>
            </Link>
          </Button>
        </Menu>
      </Container>
    </>
  );
}
