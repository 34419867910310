import styled from "styled-components";

export const Container = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--shape);
  padding: 1rem;

  .title {
    color: var(--purple);
    font-size: 2.5rem;
    line-height: 8rem;
    font-weight: 700;
  }

  .subtitle {
    color: var(--text);
    line-height: 4rem;
    font-size: 1.5rem;
    font-weight: 700;
  }
`;

export const Content = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);//TRES COLUNAS DE TAMANHOS IGUAIS
  gap: 2rem; //ESPAÇAMENTO ENTRE OS GRIDS
`;

export const CardPlan = styled.div`
  box-shadow: 1px 2px 4px 2px #DBDBDB;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.40rem;

  button {
    width: 15rem;
    margin-top: -35px;
    border: 1;
    border-color: #fff;
    border-radius: 2rem;
    padding: 1rem 1rem;
    background-color: var(--purple);
    color: var(--secondary-text);
    background: linear-gradient(180deg, var(--purple) 0%, rgba(6, 6, 6, 0.99) 99.98%, rgba(0, 0, 0, 0.82) 99.99%, rgba(100, 33, 109, 0) 100%);

  }
`;

export const CardPlanHeader = styled.header`
  padding: 1rem 4rem;
  padding-bottom: 3rem;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.40rem 0.40rem 2rem 2rem;
  background: linear-gradient(180deg, var(--purple) 0%, rgba(6, 6, 6, 0.95) 99.98%, rgba(0, 0, 0, 0.82) 99.99%, rgba(100, 33, 109, 0) 100%);
  color: var(--secondary-text);
  span{
    margin-top: 1.5rem;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  h1{
    font-size: 2.5rem;
  }
  
  h2 {
    margin: 0rem 0.3rem;
  }


`;

export const CardPlanItem = styled.ul`
  padding: 2rem 2rem;
  line-height: 2rem;
  color: var(--purple);
  li {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    text-align: left;

    h3 {
      margin-left: 0.5rem;
      font-weight: 400;
    }
  }
`;