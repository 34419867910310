import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { SliderEnterprise } from "../../components/SliderEnterprise";
import { Us } from "../../components/Us";


interface PropsDTO {
  openModalPlans: any;
}

export function Enterprise({ openModalPlans }: PropsDTO) {

  return (
    <>
      <Header openModalPlans={openModalPlans} />
      <SliderEnterprise />

      <Us />
      <Footer />
    </>
  );
}