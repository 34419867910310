import { Card, Container, Content } from "./styles";

import smileImg from '../../assets/smile.svg';
import callImg from '../../assets/call.svg';
import routerImg from '../../assets/router.svg';

export function Services() {

  return (
    <Container>
      <h1>O mundo mais perto, rápido e conectado com você</h1>
      <h2>Velocidade é tudo, seja nosso cliente e aproveite o melhor da internet banda larga</h2>
      <Content>
        <Card>
          <img src={smileImg} alt="atendimento premium" />
          <span className="title">Atendimento Premium</span>
          <span className="text">
            Temos uma equipe qualificada e disponível para te atender, identificando suas necessidades e encontrando as melhores soluções sempre que você precisar.
          </span>
        </Card>
        <Card>
          <img src={callImg} alt="suporte garantido" />
          <span className="title">Suporte Garantido</span>
          <span className="text">
          Precisa de ajuda? Conte com nossa equipe de suporte. Nosso lema é resolver no primeiro contato.
          </span>
        </Card>
        <Card>
          <img src={routerImg} alt="fibra óptica" />
          <span className="title">Fibra Óptica</span>
          <span className="text">
          Aproveite o poder de uma internet com tecnologia de ponta, equipamentos de última geração
          </span>
        </Card>
      </Content>
    </Container>
  );
}