import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Promotions } from "../../components/Promotions";
import { Services } from "../../components/Services";
import { Slider } from "../../components/Slider";

interface PropsDTO {
  openModalPlans: any;
}

export function LandingPage({openModalPlans}: PropsDTO) {

  return (
    <>
      <Header openModalPlans={openModalPlans} />
      <Promotions openModalPlans={openModalPlans} />
      <Slider />
      {/* <Plans /> */}
      <Services />
      <Footer />
    </>
  );
}