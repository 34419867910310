import React from "react";
import InputMask from "react-input-mask";
import { SubmitHandler, useForm } from "react-hook-form";
import { Container, Content, Form, FormGroup } from "./styles";

import whatsappIcon from "../../assets/whatsapp.svg";
import instagramIcon from "../../assets/instagram.svg";
import facebookIcon from "../../assets/facebook.svg";
import { Box, Link, Stack, Typography } from "@mui/material";
import { StackedBarChartRounded } from "@mui/icons-material";

interface InputsDTO {
  name: string;
  email: string;
  phoneOne: string;
  phoneTwo: string;
  message: string;
  city: string;
  region: string;
}

export function Footer() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<InputsDTO>();
  const onSubmit: SubmitHandler<InputsDTO> = (data) => console.log(data);

  return (
    <Container id="contato">
      <span className="title">ENTRE EM CONTATO CONOSCO</span>
      <Stack
        sx={{ paddingTop: 10 }}
        direction="column"
        alignItems="flex-start"
        justifyContent="center"
        spacing={13}
      >
        <div className="card">
          <a
            style={{ textDecoration: "none", color: "var(--secondary-text)" }}
            target="_blank"
            href="https://wa.me/558631421710"
            rel="noreferrer"
          >
            <div className="card-img">
              <img src={whatsappIcon} alt="WhatsApp" />
            </div>
          </a>
          <div>
            <Typography
              sx={{ textTransform: "capitalize" }}
              variant="h5"
              style={{ fontWeight: "500" }}
            >
              WhatsApp
            </Typography>
            <Link
              color="inherit"
              underline="none"
              target="_blank"
              href="https://wa.me/558631421710"
              rel="noreferrer"
            >
              <Typography
                sx={{ textTransform: "capitalize" }}
                variant="h6"
                style={{ fontWeight: "500" }}
              >
                (86) 9 9484 9699
              </Typography>
            </Link>
            {/* <Link color="inherit" underline="none" target="_blank" href="https://wa.me/5586999673850" rel="noreferrer">
              <Typography sx={{ textTransform: 'capitalize' }} variant="h6" style={{ fontWeight: '500' }}>
                (86) 9 9967 3850
              </Typography>
            </Link> */}
          </div>
        </div>

        <Link
          color="inherit"
          underline="none"
          target="_blank"
          href="https://www.instagram.com/acessenettelecom/"
          rel="noreferrer"
        >
          <div className="card">
            <div className="card-img">
              <img src={instagramIcon} alt="WhatsApp" />
            </div>
            <div>
              <Typography
                sx={{ textTransform: "capitalize" }}
                variant="h5"
                style={{ fontWeight: "500" }}
              >
                Instagram
              </Typography>
              <Typography
                sx={{ textTransform: "capitalize" }}
                variant="h6"
                style={{ fontWeight: "500" }}
              >
                @acessenetteleecom
              </Typography>
            </div>
          </div>
        </Link>

        <Link
          color="inherit"
          underline="none"
          target="_blank"
          href="https://www.facebook.com/AcesseNet-Telecom-113326757169133"
          rel="noreferrer"
        >
          <div className="card">
            <div className="card-img">
              <img src={facebookIcon} alt="WhatsApp" />
            </div>
            <div>
              <Typography
                sx={{ textTransform: "capitalize" }}
                variant="h5"
                style={{ fontWeight: "500" }}
              >
                Facebook
              </Typography>
              <Typography
                sx={{ textTransform: "capitalize" }}
                variant="h6"
                style={{ fontWeight: "500" }}
              >
                acessenet.telecom
              </Typography>
            </div>
          </div>
        </Link>
      </Stack>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="two-column">
          <FormGroup>
            <span>Nome: </span>
            <input type="text" placeholder="" {...register("name")}></input>
            {errors.name && <span>É necessário preencher o nome</span>}
          </FormGroup>
          <FormGroup>
            <span>E-mail: </span>
            <input type="text" {...register("email")} placeholder=""></input>
          </FormGroup>
          <FormGroup>
            <span>Cidade: </span>
            <input type="text" {...register("city")} placeholder=""></input>
          </FormGroup>
          <FormGroup>
            <span>Região: </span>
            <input type="text" {...register("region")} placeholder=""></input>
          </FormGroup>
          <FormGroup>
            <span>Celular 1: </span>
            <InputMask
              mask="(99) 99999-9999"
              {...register("phoneOne")}
              placeholder=""
            />
          </FormGroup>
          <FormGroup>
            <span>Celular 2: </span>
            <InputMask
              mask="(99) 99999-9999"
              type="text"
              {...register("phoneTwo")}
              placeholder=""
            />
          </FormGroup>
        </div>
        <div className="one-column">
          <FormGroup>
            <span>Mensagem: </span>
            <textarea placeholder="" {...register("message")}></textarea>
          </FormGroup>

          <button type="submit">Enviar</button>
        </div>
      </Form>
    </Container>
  );
}
