import { Container, Content, Item } from "./styles";
import { Carousel } from "@trendyol-js/react-carousel";

import acSolar from "../../assets/partners/ac-solar.jpeg";
import chaveiroErideus from "../../assets/partners/chaveiro-erideus.png";
import flexum from "../../assets/partners/flexum.jpeg";
import guanabara from "../../assets/partners/guanabara.jpeg";
import postoBotica from "../../assets/partners/posto-botica.jpeg";
import puroAcai from "../../assets/partners/puro-acai.png";
import rPlacas from "../../assets/partners/r-placas.png";
import thxDesigner from "../../assets/partners/thx-designer.png";

export function Slider() {
  const frames = [
    acSolar,
    chaveiroErideus,
    flexum,
    guanabara,
    postoBotica,
    puroAcai,
    rPlacas,
    thxDesigner,
  ];
  return (
    <Container>
      <span className="title">
        Estabelecimentos que acreditam no nosso serviço
      </span>

      <Carousel
        className="carouselPartners"
        show={4.5}
        slide={1}
        swiping={true}
        responsive={true}
        infinite={true}
        hideArrows={false}
        useArrowKeys={true}
        transition={2}
        autoSwipe={2000}
      >
        {frames.map((frame) => (
          <Item>
            <img src={frame} alt={"Logo"} />
          </Item>
        ))}
      </Carousel>
    </Container>
  );
}
