export const plans = [
  {
    city: "piripiri",
    region: [
      {
        name: "cidade",
        plans: [
          {
            plan: "200",
            price: "75,00",
            wifi: true,
            fiber: true,
            premiumSupport: true,
            freeInstall: false,
            wifi24: true,
            wifi58: true,
          },
          {
            plan: "400",
            price: "85,00",
            wifi: true,
            fiber: true,
            premiumSupport: true,
            freeInstall: false,
            wifi24: true,
            wifi58: true,
          },
          {
            plan: "600",
            price: "100,00",
            wifi: true,
            fiber: true,
            premiumSupport: true,
            freeInstall: false,
            wifi24: true,
            wifi58: true,
          },
          {
            plan: "700",
            price: "130,00",
            wifi: true,
            fiber: true,
            premiumSupport: true,
            freeInstall: false,
            wifi24: true,
            wifi58: true,
          },
        ],
      },
      {
        name: "interior",
        plans: [
          {
            plan: "50",
            price: "75,00",
            wifi: true,
            fiber: true,
            premiumSupport: true,
            freeInstall: false,
            wifi24: true,
            wifi58: true,
          },
          {
            plan: "100",
            price: "85,00",
            wifi: true,
            fiber: true,
            premiumSupport: true,
            freeInstall: false,
            wifi24: true,
            wifi58: true,
          },
          {
            plan: "200",
            price: "100,00",
            wifi: true,
            fiber: true,
            premiumSupport: true,
            freeInstall: false,
            wifi24: true,
            wifi58: true,
          },
        ],
      },
    ],
  },
];
