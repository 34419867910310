import styled from 'styled-components';

export const Container = styled.div`
  background: var(--shape);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  img {
    width: 100%;
    height: 35rem;
    object-fit: cover;
  }
  .carouselPartners{
    border: 0;
    background: var(--shape);
    width: 80rem;
  }
  .title {
    color: var(--text);
    font-size: 2.5rem;
    line-height: 8rem;
    font-weight: 700;
  }
`;

export const Content = styled.div`
  position: absolute;
  right: 3rem;
  top: 10rem;
  width: 25rem;
  height: 9rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0.30rem;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  box-shadow: var(--sombra-padrao);

  h2 {
    color: var(--blue);
  }
  
  button {
      background: var(--blue);
      color: var(--secondary-text);
      border: 0;
      border-radius: 0.25rem;
      font-size: 1.15rem;
      font-weight: 600;
      padding: 0.5rem 1.25rem;
      transition: all 0.2s;
      box-shadow: var(--sombra-padrao);

      &:hover {
        filter: brightness(0.9);
        transform: scale(1.05);
      }
    }
`;

export const Item = styled.div`
  display: flex;
  width: 10rem;
  height: 10rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    object-fit: contain;
    width: 100%;
    background-color: #FFF;
  }
`;