import {
  CardPlan,
  CardPlanHeader,
  CardPlanItem,
  Container,
  Content,
} from "./styles";

import imgChecked from "../../assets/checked.svg";
import imgUnchecked from "../../assets/unchecked.svg";
import { Link } from "@mui/material";

interface RegionPlans {
  regionPlans: { name: String; plans: Object[] };
}

export function ListPlans({ regionPlans }: RegionPlans) {
  return (
    <Container>
      <span className="title">
        Planos para você curtir por inteiro na sua casa!
      </span>
      <span className="subtitle">
        Alta perfomance para usar o wifi em seus dispositivos
      </span>

      <Content>
        {regionPlans.plans.map((plan: any, idx: number) => (
          <CardPlan key={idx}>
            <CardPlanHeader>
              <h1>{plan.plan}MB</h1>
              <h2>Plano Mega</h2>
              <span>
                <h2>R$</h2>
                <h1>{plan.price}</h1>
                <h2>/mês</h2>
              </span>
            </CardPlanHeader>
            <button>
              <Link
                color="inherit"
                underline="none"
                target="_blank"
                href={
                  "https://wa.me/558631421710?text=Quero%20ser%20Acessenet,%20com%20o%20plano%20de%20" +
                  plan.plan +
                  "MB%20pagando%20R$" +
                  plan.price +
                  "/mês!"
                }
                rel="noreferrer"
              >
                <h1>Assine Agora</h1>
              </Link>
            </button>

            <CardPlanItem className="checked">
              <li>
                <img src={imgChecked} alt="checked" />
                <h3>{plan.plan}MB</h3>
              </li>
              <li>
                <img src={plan.wifi ? imgChecked : imgUnchecked} alt="status" />
                <h3>Wi-Fi Grátis</h3>
              </li>
              <li>
                <img
                  src={plan.fiber ? imgChecked : imgUnchecked}
                  alt="status"
                />
                <h3>Fibra Óptica</h3>
              </li>
              <li>
                <img
                  src={plan.premiumSupport ? imgChecked : imgUnchecked}
                  alt="status"
                />
                <h3>Suporte Premium</h3>
              </li>
              {/* <li><img src={plan.freeInstall ? imgChecked : imgUnchecked} alt="status" /><h3>Instalação Grátis</h3></li> */}
              <li>
                <img
                  src={plan.wifi24 ? imgChecked : imgUnchecked}
                  alt="status"
                />
                <h3>Wi-Fi 2.4Ghz</h3>
              </li>
              <li>
                <img
                  src={plan.wifi58 ? imgChecked : imgUnchecked}
                  alt="status"
                />
                <h3>Wi-Fi 5.8Ghz</h3>
              </li>
            </CardPlanItem>
          </CardPlan>
        ))}
      </Content>
    </Container>
  );
}
