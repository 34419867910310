import styled from "styled-components";

export const Container = styled.div`
  padding-top: 3rem;
  padding-bottom: 5rem;
  background: var(--shape);
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text);
   h3 {
    margin-bottom: 1rem;
   }
   h4 {
    margin-bottom: 0.5rem;
   }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 1rem 0;

  
`;

export const Contract = styled.button`
  background: var(--purple);
  color: var(--secondary-text);
  border: 0;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.4rem 0.8rem;
  box-shadow: var(--sombra-padrao);
  transition: all 0.2s;

  &:hover {
    filter: brightness(0.9);
    transform: scale(1.05);
  }

  img {
    width: 1rem;
    height: 1rem;
    margin-left: 0.24rem;
  }
`;

export const ChangeLocation = styled.button`
  background: transparent;
  color: var(--purple);
  border: 0;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.4rem 0.8rem;
  transition: all 0.2s;

  &:hover {
    filter: brightness(0.9);
    transform: scale(1.05);
  }

  img {
    width: 1rem;
    height: 1rem;
    margin-left: 0.24rem;
  }
`;