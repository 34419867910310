import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem; 

  h1 {
    color: var(--purple);
  }
  
  hr {
    border: 0.01rem solid;
    color: var(--text);
    padding: 0 30%;
    margin: 1rem 0;
  }

  .story {
    padding: 0 20%;
    text-align: left;
    font-size: 1.2rem;
  }
  
`;

export const Content = styled.div`
  margin-top: 5rem;
  padding: 2rem 5rem;
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 2rem;
  `;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: var(--sombra-padrao);
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  width: 25rem;

  .title {
    background: var(--purple);
    width: 100%;
    color: var(--secondary-text);
    padding: 1rem 0rem;
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #E0E0E0;
    width: 100%;
    height: 12rem;
    padding: 4rem 2rem;
    font-weight: 600;
    text-align: center;
    border-radius: 0 0 0.5rem 0.5rem;
  }
`;

