import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Promotions } from "../../components/Promotions";
import { ListPlans } from "../../components/ListPlans";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { plans as cityRegionPlans } from "../../components/ListPlans/plans";
import { Contracts } from "../../components/Contracts";

import { Fab } from "@mui/material";

interface RegionPlansDTO {
  name: string;
  plans: Object[];
}

interface PropsDTO {
  openModalPlans: any;
}



export function Plans({ openModalPlans }: PropsDTO) {
  const { city, region } = useParams();
  const [regionPlans, setRegionPlans] = useState<RegionPlansDTO>({ name: "", plans: [{}] });

  useEffect(() => {
    setRegionPlans((cityRegionPlans.filter(obj => obj.city === city)[0].region).filter((regions) => regions.name === region)[0]);
  }, [city, region]);

  return (
    <>
      <Header openModalPlans={openModalPlans} />
      <Fab
        onClick={openModalPlans}
        sx={{
          position: 'fixed',
          bottom: 20,
          left: 20,
          zIndex: 1000,
        }}
        size='small'
        color='primary'
        variant='extended'
        aria-label="add">
        <LocationOnIcon />
        Localização
      </Fab>
      <Promotions openModalPlans={openModalPlans} />
      <ListPlans regionPlans={regionPlans} />
      <Contracts openModalPlans={openModalPlans} city={city} region={region} />
      <Footer />
    </>
  )
}