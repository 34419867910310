import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  background: var(--shape);
  box-shadow: var(--sombra-padrao);
`;
export const LogoContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 22rem;
`;

export const LogoCelphone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: var(--text);
  font-size: 0.8rem;
`;

// export const Menu = styled.ul`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-evenly;
//   align-items: center;
//   width: 40rem;
//   height: 100%;

//   /* li {
//     list-style-type: none;
//     height: 100%;
//     font-weight: 500;
//     font-size: 1.3rem;
//     transition: all 0.2s;
//     color: black;

//     &.first {
//     color: var(--purple);
//     }
       
//     &:hover{
//         color: var(--black);
//         transform: scale(1.05);
//     }
  
//   } */
  
//   a:link, a:visited {
//     color: var(--purple);
//     text-decoration: none;
//     transition: all 0.2s;
//      &.first {
//       color: var(--black);
//      }
//   }
// `;