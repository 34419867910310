import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --background: #f8f2f5;
    --shape: #ffffff;

    --purple: #64216D;
    --light-purple: #7C4584;
    --blue: #1552A7;

    --text: #868799;
    --secondary-text: #FFFFFF;
    
    --sombra-padrao: 4px 4px 3px rgba(0, 0, 0, 0.25); 
  }

  * {
    backface-visibility: hidden;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }
  body {
    background: var(--background);
    -webkit-font-smoothing: antialised;
  }

  body, input, textarea, button {
    font-weight: 400;
    font-family: "Roboto", sans-serif;
  }

  button {
    cursor: pointer;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .react-modal-overlay {
    z-index: 1;
    background: rgba(0,0,0, 0.5);
    position: fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;

    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .react-modal-content {
    margin-top: 9rem;
    width: auto;
    max-width: 576px;
  }

  .react-modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background: transparent;

    transition: filter 0.2s;

    &:hover{
      filter: brightness(0.8);
    }
  }
`;