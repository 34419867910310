import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Container, FormControl } from "./styles";
import { plans } from "../ListPlans/plans";
import Modal from 'react-modal';


import closeImg from "../../assets/close.svg";

interface PropsDTO {
  modalPlans: boolean;
  handleClose: any;
}


Modal.setAppElement('#root');

export function ChangeLocation({ modalPlans, handleClose }: PropsDTO) {
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");

  const navigate = useNavigate();

  function handleSubmit(e: FormEvent) {
    e.preventDefault();

    navigate('/planos/' + city + '/' + region);

    handleClose();
  }


  return (
    <Modal
      isOpen={modalPlans}
      onRequestClose={handleClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button type="button"
        onClick={handleClose}
        className="react-modal-close">
        <img src={closeImg} alt="Fechar modal" />
      </button>

      <Container>
        <h3>Selecione a cidade e região:</h3>
        <FormControl>
          <select
            style={{ textTransform: 'capitalize' }}
            name="city"
            placeholder="CIDADE"
            value={city}
            onChange={(e) => setCity(e.target.value)}>
            <option value={0}>Selecione a cidade</option>

            {plans.map(value =>
              <option key={value.city} value={value.city} >{value.city}</option>)}

          </select>
          <select
            style={{ textTransform: 'capitalize' }}
            name="regiao"
            placeholder="REGIÃO"
            value={region}
            onChange={(e) => setRegion(e.target.value)}>
            <option>Selecione a região</option>

            {city && (plans?.filter(obj => obj.city === city)[0].region)?.map((region, idx) =>
              <option key={idx} value={region.name} >{region.name}</option>
            )}


          </select>
        </FormControl>
        <button disabled={!city && !region} onClick={handleSubmit}>
          Confirmar
        </button>
      </Container>
    </Modal>
  );
}