import { Container, Content, Contract, ChangeLocation } from "./styles";

import contractImg from '../../assets/contract.svg';
import locationImg from '../../assets/location.svg';

interface PropsContractDTO {
  city: string | undefined;
  region: string | undefined;
  openModalPlans: any;
}

export function Contracts({ city, region, openModalPlans }: PropsContractDTO) {

  return (
    <Container>
      <h3>Os plainos acima podem estar ligados com os contratos abaixo</h3>
      <h4> *Planos disponíveis apenas para {city} na área {region}. Para outras localidades, altere a localização ou entre em contato:</h4>
      <h4>(86) 9 9484 9699 - (86) 9 9967 3850</h4>

      <Content>
        <Contract>
          Contrato de Locação
          <img src={contractImg} alt="contract" />
        </Contract>
      </Content>

      <ChangeLocation onClick={openModalPlans}>
        Alterar Localização
        <img src={locationImg} alt="location" />
      </ChangeLocation>
    </Container>
  );
}