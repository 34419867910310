import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem 4rem;
  background: #353535;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  
   .title{
    background: var(--shape);
    font-weight: 700;
    font-size: 1.5rem;
    padding: 0.3rem 2rem;
    border-radius: 0 1rem 1rem 0;
    position: absolute;
    left: 0;
    }
    .card {
    color: var(--secondary-text);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    line-height: 2rem;

    .card-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6rem;
      height: 6rem;
      margin-right: 1rem;
      background: var(--shape);
      border-radius: 50%;
    }
  }
`;

export const Content = styled.div`
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;  
  
`;

export const Form = styled.form`
  padding: 1rem 2rem;
  width: 40rem;
  background: var(--shape);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;

  .one-column {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    justify-items:center;

  }

  .two-column {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 1rem;
  }

  button {
    color: var(--secondary-text);
    height: 3rem;
    border: 0;
    background: #353535;
    width: 15rem;
    transition: all 0.5s;
    border-radius: 0.5rem;
    font-size: 1.5rem;
    font-weight: 500;

    &:hover {
      filter: brightness(0.9);
      transform: scale(1.03);
    }
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  width: 100%;

  span{
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    
  }

  textarea:focus, input:focus {
    box-shadow: 0 0 10px #353535;
    border: 0 none;
    outline: 0;
  } 
  
  textarea {
    resize: none;
    box-shadow: rgba(0, 0, 0, 0.50) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    transition: all 0.5s;
    border: 0 solid #B5B5B5;
    border-radius: 0.25rem;
    height: 10rem;
    padding: 0.5rem;
    color: black;
    font-size: 1.5rem;
  }  

  input {
    box-shadow: rgba(0, 0, 0, 0.50) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    transition: all 0.5s;
    border: 0 solid #B5B5B5;
    border-radius: 0.25rem;
    height: 2.3rem;
    padding: 0.5rem;
    color: black;
    font-size: 1.5rem;
  }
`;