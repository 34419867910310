import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ChangeLocation } from "./components/ChangeLocation";
import { Enterprise } from "./container/Enterprise";
import { LandingPage } from "./container/LandingPage";
import { Plans } from "./container/Plans";
import { GlobalStyle } from "./styles/global";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Backdrop, CircularProgress, Fab } from "@mui/material";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#64216D",
      light: "#7C4584",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#0066ff",
      main: "#0044ff",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
    // dois índices dentro de sua paleta tonal.
    // Por exemplo, mude de Red 500 para Red 300 ou Red 700.
    tonalOffset: 0.2,
  },
});

export function App() {
  const [modalPlans, setModalPlans] = useState<boolean>(false);
  const [loadingPage, setLoadingPage] = useState<boolean>(true);

  const handleStartLoading = () => setLoadingPage(true);
  const handleStopLoading = () => setTimeout(() => setLoadingPage(false), 500);

  const handleOpen = () => setModalPlans(true);
  const handleClose = () => setModalPlans(false);

  useEffect(() => {
    handleStopLoading();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingPage}
      >
        <CircularProgress size={70} thickness={4} color="primary" />
      </Backdrop>

      <Fab
        href="https://wa.me/558631421710"
        target="_blank"
        rel="noreferrer"
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 1000,
          backgroundColor: "green",
        }}
        color="primary"
        size="medium"
        aria-label="add"
      >
        <WhatsAppIcon />
      </Fab>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<LandingPage openModalPlans={handleOpen} />}
          />
          <Route
            path="/empresa"
            element={<Enterprise openModalPlans={handleOpen} />}
          />
          <Route
            path="/planos/:city/:region"
            element={<Plans openModalPlans={handleOpen} />}
          />
        </Routes>
        <ChangeLocation handleClose={handleClose} modalPlans={modalPlans} />
      </BrowserRouter>
    </ThemeProvider>
  );
}
